const actions = {
    SET_ITEM_PROPERTY: 'SET_ITEM_PROPERTY',
    ADD_PHOTO: 'ADD_PHOTO',
    SUBMIT_ITEM_TO_INVENTORY: 'SUBMIT_ITEM_TO_INVENTORY',
    SUBMIT_ITEM_TO_INVENTORY_COMPLETE: 'SUBMIT_ITEM_TO_INVENTORY_COMPLETE',
    SEND_PHOTO: 'SEND_PHOTO',
    SEND_PHOTO_COMPLETE: 'SEND_PHOTO_COMPLETE',
    DELETE_ITEM: 'DELETE_ITEM',
    GET_ITEMS: 'GET_ITEMS',
    GET_ITEMS_COMPLETE: 'GET_ITEMS_COMPLETE',
    GET_ITEM_SINGLE: 'GET_ITEM_SINGLE',
    GET_ITEM_SINGLE_COMPLETE: 'GET_ITEM_SINGLE_COMPLETE',
    LIST_ITEMS_PADDING_TOP: 'LIST_ITEMS_PADDING_TOP',
    CLEAR_EDITOR_FIELDS: 'CLEAR_EDITOR_FIELDS',
    GET_LOCATIONS: 'GET_LOCATIONS',
    GET_LOCATIONS_COMPLETE: 'GET_LOCATIONS_COMPLETE',
    SET_LOCATION_BY_ID: 'SET_LOCATION_BY_ID',
    SUBMIT_LOCATION_TO_INVENTORY: 'SUBMIT_LOCATION_TO_INVENTORY',
    SUBMIT_LOCATION_TO_INVENTORY_COMPLETE:
        'SUBMIT_LOCATION_TO_INVENTORY_COMPLETE',
    DELETE_LOCATION: 'DELETE_LOCATION',
    SET_LOCATION_PROPERTY: 'SET_LOCATION_PROPERTY',
    GET_TAG_OPTIONS_FOR_STRING: 'GET_TAG_OPTIONS_FOR_STRING',

    // inventory actions
    ADD_NEW_TAG_TO_ITEM: 'ADD_NEW_TAG_TO_ITEM',
    ADD_TAG_OPTIONS_TO_EDITOR: 'ADD_TAG_OPTIONS_TO_EDITOR',
};
export default actions;
